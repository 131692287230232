html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

main {
  padding-bottom: 51px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

main h1 {
  margin: 0;
  font-size: 10vw;
}

main h2 {
  margin: 0;
  font-size: 3vw;
}
