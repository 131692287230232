.logo {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 1rem;
}

.hidden {
  display: none;
}

span {
  font-weight: 600;
}

@media screen and (min-width: 600px) {
  span {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 650px) {
  span {
    font-size: 1.5rem;
  }
}
