footer {
  width: 100%;
  height: 51px;
  position: fixed;
  bottom: 0;
  display: flex;
  gap: 10vw;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: black;
}
