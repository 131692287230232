@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto+Mono&display=swap");

.about {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.about > p {
  text-align: center;
  margin: 0;
  font-weight: 500;
}
.profile-photo {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.profile-photo img {
  border-radius: 50%;
  max-height: 100%;
  max-width: 100%;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.description span {
  font-weight: 500;
}

.languages {
  display: flex;
}

.languages img {
  height: 2.5rem;
  padding: 0.5rem;
}

.tech-stack {
  display: flex;
  padding: 0 1rem;
  justify-content: center;
}

.tech-stack img {
  height: 2.5rem;
  padding: 0.5rem;
}

@media screen and (min-width: 768px) {
  .tech-stack img {
    height: 3rem;
    padding: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .tech-stack img {
    height: 4rem;
    padding: 0.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .description {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.2rem;
  }
}
