.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.company {
  display: flex;
  max-width: 100%;
  overflow: hidden;
}

.name {
  border-bottom: 1px solid;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 3rem;
}

.description {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.skills ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.skills ul li {
  padding: 0 0.5rem;
}

@media screen and (max-width: 900px) {
  .logo img {
    width: 2rem;
  }

  .item {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .responsibilities {
    font-size: 0.8rem;
    padding: 0 1rem;
    max-width: 100%;
  }

  .responsibilities ul {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .item {
    max-width: 100%;
  }
}
