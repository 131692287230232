@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.mobile-links {
  position: fixed;
  height: calc(100vh - 51px);
  width: 100%;
  text-align: center;
  background-color: white;
  color: black;
  font-family: "Roboto Mono", monospace;
  z-index: 1;
}

.mobile-links ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 2rem;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-links a {
  color: black;
}

.mobile-links a.active {
  text-decoration: underline;
}
