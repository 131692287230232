.experience {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  font-family: "Montserrat", sans-serif;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
