a {
  text-decoration: none;
  color: white;
}

header {
  display: flex;
  position: sticky;
  width: 100%;
  z-index: 1;
  background-color: black;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 1rem;
}

.laptop-header {
  display: none;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.laptop-header p {
  margin: 0;
}

.links {
  display: none;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.links a:hover,
a.active {
  text-decoration: underline;
}

.logo .company {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .mobile-header {
    display: none;
  }

  .laptop-header {
    display: flex;
  }

  .links {
    display: flex;
  }
}

@media screen and (min-width: 1300px) {
  .laptop-header {
    gap: 2rem;
  }
}

@media screen and (min-width: 1300px) {
  .laptop-header {
    gap: 3rem;
  }
}
