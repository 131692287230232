@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto+Mono&display=swap");

.education {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: white;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.education hr {
  width: 5rem;
}

.university {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.university span ~ span {
  font-size: 1.2rem;
}

.university span ~ span ~ span {
  font-weight: 400;
  font-size: 1rem;
}

@media screen and (max-width: 900px) {
  .university > span {
    font-size: 1.2rem;
  }

  .university span ~ span {
    font-size: 0.8rem;
  }

  .university span ~ span ~ span {
    font-weight: 400;
    font-size: 0.7rem;
  }
}
